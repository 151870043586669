import Axios from "axios";
import * as FileSaver from "file-saver";
import moment from "moment";
import { API_ADDRESS } from "..";
import { GetToken } from "../helper";
import { GetTaskRequestConfig } from "./OrderService";

export async function GetReports() {
  const response = await Axios.get(API_ADDRESS + "/api/report/", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}
export async function GetLogs() {
  const response = await Axios.get(API_ADDRESS + "/api/report/GetLogs", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}
export async function GetAllMachineList() {
  const response = await Axios.get(
    API_ADDRESS + "/api/Service/GetAllMachineList",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}
export async function GetServiceLogForMachine(machineId: string) {
  const response = await Axios.get(
    API_ADDRESS + `/api/Service/GetServiceLog?machineId=${machineId}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}
export async function CreateServiceLogForMachine({
  machine_id,
  details,
  operations,
  customer,
}: {
  machine_id: number;
  details: string;
  operations: string;
  customer: string;
}) {
  const convertedOperations = JSON.stringify([operations]);

  const body = {
    machine_id,
    details,
    operations: convertedOperations,
    customer,
  };

  const response = await Axios.post(
    API_ADDRESS + `/api/Service/AddServiceLog`,
    body,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetReportsWithDate(selectedDate: string) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/report/?date_parameter=${moment(
      selectedDate,
      "YYYY-MM-DD"
    )
      .add("hours", 3)
      .toISOString()}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}
export async function GetUsedMaterials(startDate: string, endDate: string) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/report/GetUsedMaterials/?start_date=${startDate}&end_date=${endDate}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });
  return response;
}

export class ExportCSVModel extends GetTaskRequestConfig {
  OrderStartDate: string = "";
  OrderEndDate: string = "";
  StatusStartDate: string = "";
  StatusEndDate: string = "";
}

export async function ExportCSVReport(model: ExportCSVModel) {
  const response = await Axios.put(
    API_ADDRESS + "/api/report/ExportToCSV",
    model,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
      responseType: "blob",
    }
  )
    .then((res) => {
      if (res.status === 200) {
        FileSaver.saveAs(res.data, "rapor.xlsx");
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}
export async function ExportCSVReportWithGroup(model: ExportCSVModel) {
  const response = await Axios.put(
    API_ADDRESS + "/api/report/ExportToCSVByGroupMachine",
    model,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
      responseType: "blob",
    }
  )
    .then((res) => {
      if (res.status === 200) {
        FileSaver.saveAs(res.data, "rapor.xlsx");
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function ExportCSVReportProducedMachines(model: ExportCSVModel) {
  const response = await Axios.put(
    API_ADDRESS + "/api/report/ExportToCSVProducedMachines",
    model,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
      responseType: "blob",
    }
  )
    .then((res) => {
      if (res.status === 200) {
        FileSaver.saveAs(res.data, "rapor.xlsx");
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}
