import { Modal } from "antd";
import Axios from "axios";
import { API_ADDRESS } from "..";
import { GetToken } from "../helper";

export function addConfig(key: string, values: Object) {
  return Axios.post(`${API_ADDRESS}/api/config/${key}`, values, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      Modal.success({
        title: "İşlem Başarili.",
        content:
          "Yeni özellik başariyla eklendi, görmek için sayfayi yenileyin..",
      });
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Bir Hata Oluştu.",
        content: "Lütfen tekrar deneyin.",
      });
      return false;
    });
}
export function deleteConfig(values: Object) {
  return Axios.put(`${API_ADDRESS}/api/config/`, values, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      Modal.success({
        title: "İşlem Başarili.",
        content: "Özellik başariyla silindi, görmek için sayfayi yenileyin..",
      });
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Bir Hata Oluştu.",
        content: "Lütfen tekrar deneyin.",
      });
      return false;
    });
}

export function getCustomerList() {
  return Axios.get(`${API_ADDRESS}/api/config/GetCustomerList`, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
}

export function getOrderNumber() {
  return Axios.get(`${API_ADDRESS}/api/Task/GetOrderNumber`, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
}

export function getUsedMachineNumber() {
  return Axios.get(`${API_ADDRESS}/api/Order/GetUsedMachineNumber`, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
}

export function getUsedMachineById(id: number) {
  return Axios.get(`${API_ADDRESS}/api/Order/GetUsedMachineById/${id}`, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
}
