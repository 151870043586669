/* eslint-disable eqeqeq */
import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Popconfirm, Select, Table } from "antd";
import React from "react";
import { Capitalize, ConvertDateTime } from "../helper";
import {
  DeleteUsedMachine,
  GetAllUsedMachines,
  SellUsedMachine,
} from "../services/OrderService";

import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import { CSVLink } from "react-csv";
import { renderToString } from "react-dom/server";
import { renderProperties } from "./OrderScreen";
dayjs.locale("tr");
const QRCode = require("qrcode.react");

const { Option, OptGroup } = Select;

export default class UsedMachinesScreen extends React.Component {
  configFile = JSON.parse(localStorage.getItem("config") ?? "{}");

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Bul
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    csvdata: [
      [
        "Seri No",
        "Sipariş No",
        "Sipariş Tarihi",
        "Müşteri",
        "Telefon",
        "Adres",
        "Sipariş Alan",
        "Sipariş Özellikleri",
        "Durum",
        "Üretim Birimi",
      ],
    ],
    status: "active",
    movableMachines: [],
    orderModalVisible: false,
    orderDetails: {},
    loading: false,
    size: "small",
    top: "none",
    bottom: "bottomRight",
    ellipsis: true,
    data: [],
    currencyForModal: "₺",
    columns: [
      {
        title: "Durum",
        dataIndex: "status",
        sorter: (a, b) => a.status.localeCompare(b.status),
        ...this.getColumnSearchProps("status"),
        render: (text) => (
          <td>
            {text === "active"
              ? "Bahçede"
              : text === "sold"
              ? "Satıldı"
              : text === "cancelled"
              ? "İptal"
              : Capitalize(text)}
          </td>
        ),
      },
      {
        title: "Alınan Müşteri",
        dataIndex: "purchased_customer",
        sorter: (a, b) =>
          a.purchased_customer?.localeCompare(b?.purchased_customer),
        ...this.getColumnSearchProps("purchased_customer"),
      },
      {
        title: "Seri No",
        dataIndex: "serial_number",
        sorter: (a, b) => a.serial_number.localeCompare(b.serial_number),
        ...this.getColumnSearchProps("serial_number"),
      },
      {
        title: "Ürün",
        dataIndex: "description",
        render: (text) => <td>{Capitalize(text)}</td>,
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        title: "Oluşturulma Tarihi",
        dataIndex: "row_created_date",
        defaultSortOrder: "descend",
        render: (text) => <td>{ConvertDateTime(text)}</td>,
        sorter: (a, b) =>
          dayjs(a.row_created_date).unix() - dayjs(b.row_created_date).unix(),
      },
    ],
  };

  getOrders = () => {
    GetAllUsedMachines(this.state.status).then((res) => {
      this.setState({ data: res });

      let csvdata = [
        [
          "Yıl",
          "Seri No",
          "Alınan Müşteri",
          "Alım Tarihi",
          "Alınan Fiyat",
          "Satılan Müşteri",
          "Satılma Tarihi",
          "Satılan Fiyat",
          "Ödeme Detayı",
        ],
      ];

      res?.forEach((machine) => {
        csvdata.push([
          machine.year,
          machine.serial_number,
          machine.purchased_customer,
          machine.row_created_date,
          machine.price + " " + machine.currency,
          machine.customer,
          machine.sold_date,
          machine.sold_price + " " + machine.sold_currency,
          machine.payment_detail,
        ]);
      });
      this.setState({ csvdata });
    });
  };

  componentDidMount = () => {
    this.getOrders();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.status !== prevState.status) {
      this.getOrders();
    }
  };

  printQRCode(value, serialNumber) {
    let printWindow = window.open("", "", "height=1024, width=1024");

    let svg = renderToString(
      <div style={{ width: "50mm", height: "64mm" }}>
        <QRCode
          value={value}
          style={{ height: "44mm", width: "44mm", margin: "3mm" }}
          renderAs="svg"
        />
        <p style={{ margin: 10 }}>{serialNumber}</p>
      </div>
    );
    printWindow?.document.write(String(svg));

    printWindow.document.body.style.margin = 0;
    printWindow?.document.close();
    printWindow?.print();
  }

  render() {
    const state = this.state;

    return (
      <>
        <Select
          style={{ margin: "8px" }}
          value={this.state.status}
          onSelect={(status) => {
            this.setState({ ...this.state, status });
          }}
        >
          <Option value="active">Aktif</Option>
          <Option value="sold">Satıldı</Option>
          <Option value="cancelled">İptal</Option>
        </Select>
        <Table
          rowKey={(row) => "key" + row.id}
          size="small"
          loading={this.state.loading}
          pagination={{ defaultPageSize: 10, position: ["bottomRight"] }}
          columns={this.state.columns}
          dataSource={state.data}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div style={{ marginLeft: "40px" }}>
                  <>
                    {renderProperties("Seri No", record.serial_number)}
                    {renderProperties("Ürün", record.description)}
                    {renderProperties(
                      "Oluşturulma Tarihi",
                      ConvertDateTime(record.row_created_date)
                    )}
                    {renderProperties(
                      "Marka",
                      this.configFile.brand.filter(
                        (b) => b.type === record.brand
                      )[0]?.text
                    )}
                    {renderProperties(
                      "Tip",
                      this.configFile.type.filter(
                        (b) => b.type === record.type
                      )[0]?.text
                        ? this.configFile.type.filter(
                            (b) => b.type === record.type
                          )[0]?.text
                        : this.configFile.machine_type.filter(
                            (b) => b.type === record.type
                          )[0]?.text
                    )}
                    {renderProperties(
                      "Fan Tipi",
                      this.configFile.cooler_type.filter(
                        (b) => b.type === record.cooler_type
                      )[0]?.text
                    )}
                    {renderProperties("Kazan Boyutu", record.tank_size + " LT")}
                    {renderProperties(
                      "Pompa",
                      this.configFile.pump.filter(
                        (b) => b.type === record.pump
                      )[0]?.text
                    )}
                    {renderProperties("Pompa Seri No", record?.pump_serial)}
                    {renderProperties("Model Yılı", record.year)}
                    {renderProperties(
                      "Meme Tipi",
                      this.configFile.meme_type.filter(
                        (b) => b.type === record.meme_type
                      )[0]?.text
                    )}
                    {renderProperties(
                      "Mixer Tipi",
                      this.configFile.mixer_type.filter(
                        (b) => b.type === record.mixer_type
                      )[0]?.text
                    )}
                    {renderProperties("Meme Aralığı", record.meme_distance)}
                    {renderProperties(
                      "Çeki Tipi",
                      this.configFile.tow_type.filter(
                        (b) => b.type === record.tow_type
                      )[0]?.text
                    )}
                    {renderProperties(
                      "Kol Tipi",
                      this.configFile.arm_type.filter(
                        (b) => b.type === record.arm_type
                      )[0]?.text
                    )}
                    {renderProperties("Kol Uzunluğu", record.arm_length)}
                    {renderProperties(
                      "Şase Rengi",
                      this.configFile.chassis_color.filter(
                        (b) => b.type === record.chassis_color
                      )[0]?.text
                    )}
                    {renderProperties(
                      "Kazan Rengi",
                      Capitalize(record.caldron_color)
                    )}
                    {renderProperties(
                      "Kazan Tipi",
                      this.configFile.caldron_type.filter(
                        (b) => b.type === record.caldron_type
                      )[0]?.text
                    )}
                    {renderProperties(
                      "Kumanda",
                      this.configFile.commander.filter(
                        (b) => b.type === record.commander
                      )[0]?.text
                    )}
                    {renderProperties(
                      "Alternatif Özellikler",
                      record.alt_features
                    )}
                    {record.price &&
                      renderProperties(
                        "Alış Fiyatı",
                        record.price + " " + record.currency
                      )}
                    {renderProperties(
                      "Alınan Müşteri ",
                      record.purchased_customer
                    )}
                    {renderProperties("Alınan Müşteri TCKN", record.tckn)}

                    {renderProperties("Alınan Müşteri Adresi", record.address)}
                    {renderProperties(
                      "Alınan Müşteri Telefonu",
                      record.phone_number
                    )}

                    {record.sold_date &&
                      renderProperties(
                        "Satılma Tarihi",
                        ConvertDateTime(record.sold_date)
                      )}
                    {record.sold_price &&
                      renderProperties(
                        "Satış Fiyatı",
                        record.sold_price + " " + record.sold_currency
                      )}
                    {renderProperties("Satılan Müşteri ", record.customer)}
                    {renderProperties("Satılan Müşteri TCKN", record.sold_tckn)}
                    {renderProperties(
                      "Satılan Müşteri Adresi",
                      record.sold_address
                    )}
                    {renderProperties(
                      "Satılan Müşteri Telefonu",
                      record.sold_phone_number
                    )}
                    {renderProperties("Ödeme Detayı", record.payment_detail)}
                  </>

                  {record.status === "active" && (
                    <>
                      <Popconfirm
                        title="Bu makinayı silmek istediğinizden emin misiniz?"
                        onConfirm={() => {
                          DeleteUsedMachine(record.id).then((res) => {
                            if (res) {
                              this.setState({
                                data: this.state.data.map((d) =>
                                  d.id !== record.id
                                    ? d
                                    : { ...d, status: "cancelled" }
                                ),
                              });
                            }
                          });
                        }}
                        okText="Evet"
                        cancelText="Hayır"
                      >
                        <Button
                          danger
                          type="primary"
                          style={{ marginRight: "4px" }}
                        >
                          Makinayı Sil
                        </Button>
                      </Popconfirm>
                      <Button
                        style={{ marginRight: "4px" }}
                        type="primary"
                        onClick={() =>
                          this.setState({
                            orderModalVisible: true,
                            orderDetails: record,
                          })
                        }
                      >
                        Satıldı Olarak İşaretle
                      </Button>
                      <Button
                        style={{ marginRight: "4px" }}
                        type="primary"
                        onClick={() =>
                          this.props.history.push("/createMachine/" + record.id)
                        }
                      >
                        Makineyi Düzenle
                      </Button>
                    </>
                  )}

                  <Button
                    style={{ marginRight: "4px" }}
                    onClick={() => {
                      let qrCodeValue =
                        record.serial_number + "," + record.description;
                      this.printQRCode(qrCodeValue, record.serial_number);
                    }}
                  >
                    Qr Kodu Yazdır
                  </Button>
                  <Button
                    onClick={() => {
                      this.props.history.push(
                        "/servicerecords/" + record.serial_number
                      );
                    }}
                  >
                    Servis Kayıtları
                  </Button>
                </div>
              );
            },
          }}
        />

        <Modal
          title={"Siparişi Satıldı Olarak Güncelle"}
          open={this.state.orderModalVisible}
          onOk={() => {
            this.setState({ confirmButtonLoadingForModals: true });
            const values = {
              id: this.state.orderDetails.id,
              customer: this.state.customerForModal,
              price: this.state.priceForModal,
              payment_detail: this.state.detailForModal,
              currency: this.state.currencyForModal,
              sold_tckn: this.state.tcknForModal,
              sold_phone_number: this.state.phoneNumberForModal,
              sold_address: this.state.addressForModal,
            };
            SellUsedMachine(values).then((res) => {
              this.setState({
                confirmButtonLoadingForModals: false,
              });

              if (res) {
                this.setState({
                  orderModalVisible: false,
                  orderDetails: undefined,
                  customerForModal: "",
                  priceForModal: "",
                  detailForModal: "",
                  tcknForModal: "",
                  phoneNumberForModal: "",
                  addressForModal: "",
                  currencyForModal: "₺",
                });
                this.getOrders();
              }
            });
          }}
          confirmLoading={this.state.confirmButtonLoadingForModals}
          okText="Gönder"
          onCancel={() => {
            this.setState({ orderModalVisible: false });
          }}
        >
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Müşteri"
              name="customer"
              rules={[
                {
                  required: true,
                  message: "Bu alanı doldurun!",
                },
              ]}
            >
              <Input
                onChange={(e) =>
                  this.setState({ customerForModal: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              name="tckn"
              label="TCKN"
              rules={[{ max: 11, message: "Maksimum sınır aşıldı." }]}
              required
            >
              <Input
                maxLength={11}
                onChange={(e) =>
                  this.setState({ tcknForModal: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              name="phone_number"
              label="Telefon No"
              initialValue={"+90"}
              rules={[
                { required: true, message: "Bu alan boş bırakılamaz" },
                {
                  max: 13,
                  min: 13,
                  message: "Telefon numarası 13 haneden oluşmalıdır.",
                },
              ]}
              required
            >
              <Input
                onChange={(e) =>
                  this.setState({ phoneNumberForModal: e.target.value })
                }
                placeholder="Ülke alan koduyla birlikte girin.."
                maxLength={13}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="address"
              label="Adres"
              rules={[
                { required: true, message: "Bu alan boş bırakılamaz" },
                { max: 250, message: "Maksimum sınır aşıldı." },
              ]}
              required
            >
              <Input
                onChange={(e) =>
                  this.setState({ addressForModal: e.target.value })
                }
              />
            </Form.Item>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <Form.Item
                style={{ flex: 1 }}
                required
                name="price"
                label="Satış Fiyatı"
              >
                <Input
                  value={this.state.priceForModal}
                  type="number"
                  onChange={(e) =>
                    this.setState({ priceForModal: e.target.value })
                  }
                />
              </Form.Item>

              <Form.Item
                style={{ width: "80px" }}
                name="currency"
                initialValue="₺"
                required
              >
                <Select
                  value={this.state.currencyForModal}
                  onSelect={(e) => {
                    this.setState({ currencyForModal: e });
                  }}
                >
                  <Option value="₺">₺</Option>
                  <Option value="€">€</Option>
                  <Option value="$">$</Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item
              label="Ödeme Detayı"
              name="payment_detail"
              rules={[
                {
                  required: true,
                  message: "Bu alanı doldurun!",
                },
              ]}
            >
              <TextArea
                value={this.state.detailForModal}
                rows={3}
                maxLength={255}
                onChange={(e) =>
                  this.setState({ detailForModal: e.target.value })
                }
              />
            </Form.Item>
          </Form>
        </Modal>
        <Button
          loading={this.state.loading}
          style={{ float: "right", marginTop: "8px", marginRight: 10 }}
          type="default"
        >
          <CSVLink data={state.csvdata}>CSV'ye aktar</CSVLink>
        </Button>
      </>
    );
  }
}
