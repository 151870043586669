/* eslint-disable eqeqeq */
import { UndoOutlined } from "@ant-design/icons";
import { Button, Form, Input, Layout, Radio, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import IAgrotekConfig from "../IAgrotekConfig";
import { padLeft } from "../helper";
import {
  getUsedMachineById,
  getUsedMachineNumber,
} from "../services/ConfigService";
import { CreateUsedMachine, UpdateUsedMachine } from "../services/OrderService";
import { Body } from "./MainScreen";

const { Option, OptGroup } = Select;
const QRCode = require("qrcode.react");
const { Content } = Layout;

function CreateMachineScreen() {
  const configFile: IAgrotekConfig = JSON.parse(
    localStorage.getItem("config") ?? "{}"
  );
  const [isFetching, setIsFetching] = useState(false);
  const [orderNoCounter, setOrderNoCounter] = useState(0);
  const [qrCodeValue, setQrCodeValue] = useState("");
  const [productionUnit, setProductionUnit] = useState("turbo");
  const history = useHistory();

  const [serialState, setSerialState] = useState({
    brand: "",
    type: "",
    cooler_type: "",
    mixer_type: "",
    arm_type: "",
    tank_size: 0,
    pump: "",
    machine_no: "",
    order_amount: 0,
    year: "",
    proforma_no: "",
  });

  const params = useLocation();
  const machineId = params.pathname.split("/")[2];

  let serialCode = SerialCodeGenerator();

  const [form] = Form.useForm();

  const createOrderNo = () => {
    setIsFetching(true);
    getUsedMachineNumber().then((res) => {
      setOrderNoCounter(15);
      setSerialState((prev) => ({
        ...prev,
        machine_no: String(res),
      }));

      setIsFetching(false);
    });
  };

  useEffect(() => {
    if (orderNoCounter > 0) {
      setTimeout(() => {
        setOrderNoCounter(orderNoCounter - 1);
      }, 1000);
    }
  }, [orderNoCounter]);

  useEffect(() => {
    if (!machineId) {
      createOrderNo();
    } else {
      getUsedMachineById(Number(machineId)).then((res) => {
        const result: any = { ...form.getFieldsValue() };
        Object.keys(res).forEach((key) => {
          if (res[key]) {
            result[key] = res[key];
          }
        });
        const brand = configFile.brand.find((brand) => brand.type == res.brand);

        const type = configFile.type.find((type) => type.type == res.type);

        const cooler_type = configFile.cooler_type.find(
          (type) => type.type == res.cooler_type
        );

        const arm_type = configFile.arm_type.find(
          (type) => type.type == res.arm_type
        );

        const tank_size = configFile.tank_size.find(
          (size) => size.type == res.tank_size
        );
        const pump = configFile.pump.find((pump) => pump.type == res.pump);
        const mixer_type = configFile.mixer_type.find(
          (mixer) => mixer.type == res.mixer_type
        );

        setSerialState({
          brand: brand?.shortend || "",
          type: type?.shortend || "",
          cooler_type: cooler_type?.shortend || "",
          mixer_type: mixer_type?.shortend || "",
          arm_type: arm_type?.shortend || "",
          tank_size: Number(tank_size?.shortend) || 0,
          pump: pump?.shortend || "",
          machine_no: res.machine_no || "",
          order_amount: Number(res.order_amount) || 0,
          year: String(res.year) || "",
          proforma_no: String(res.proforma_no) || "",
        });

        setProductionUnit(res.production_unit);

        form.setFieldsValue(result);
      });
    }
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content>
        <Body
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap-reverse",
          }}
        >
          <Form
            form={form}
            onFieldsChange={() => {
              let product =
                form.getFieldValue("brand") == "turktraktor"
                  ? form.getFieldValue("brand") +
                    " " +
                    form.getFieldValue("model")
                  : form.getFieldValue("production_unit") == "turbo"
                  ? form.getFieldValue("brand") +
                    " " +
                    form.getFieldValue("tank_size") +
                    "LT " +
                    form.getFieldValue("cooler_type") +
                    form.getFieldValue("fan_radius") +
                    "cm " +
                    form.getFieldValue("fan_type")
                  : form.getFieldValue("production_unit") == "kollu"
                  ? form.getFieldValue("brand") +
                    " " +
                    form.getFieldValue("tank_size") +
                    "LT " +
                    form.getFieldValue("arm_type") +
                    " " +
                    form.getFieldValue("arm_length")
                  : form.getFieldValue("production_unit") == "diger"
                  ? form.getFieldValue("brand") +
                    " " +
                    form.getFieldValue("type")
                  : "";
              setQrCodeValue(serialCode + "," + product.toUpperCase());
              if (
                form.getFieldValue("model") &&
                form.getFieldValue("brand") == "turktraktor"
              ) {
                form.setFieldsValue({
                  caldron_color: form.getFieldValue("model"),
                });
              }
            }}
            onFinish={async (values) => {
              if (machineId) {
                setIsFetching(true);
                const resp = await UpdateUsedMachine(
                  { ...values, id: machineId, production_unit: productionUnit },
                  serialCode
                );
                setIsFetching(false);
                if (resp) {
                  history.push("/usedmachines");
                }
              } else {
                setIsFetching(true);
                setOrderNoCounter(0);
                values.machine_no = serialState.machine_no;
                const resp = await CreateUsedMachine(
                  { ...values, production_unit: productionUnit },
                  serialCode
                );
                setIsFetching(false);
                if (!resp) {
                  getUsedMachineNumber();
                } else {
                  history.push("/usedmachines");
                }
              }
            }}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: "calc(100% - 168px)", minWidth: "450px" }}
            requiredMark={false}
          >
            {/*    <Form.Item
              name="date"
              initialValue={moment()}
              label="Tarih"
              required
            >
              <DatePicker disabled />
            </Form.Item> */}

            <Form.Item name="production_unit" label="Üretim Birimi" required>
              <Radio.Group
                defaultValue={"turbo"}
                value={productionUnit}
                onChange={(e) => {
                  setProductionUnit(e.target.value);
                }}
              >
                <Radio value="turbo">Turbo</Radio>
                <Radio value="kollu">Kollu</Radio>
                <Radio value="diger">Diğer</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="year" label="Yıl" required>
              <Input style={{ width: "324px" }} type="number" />
            </Form.Item>

            <Form.Item name="brand" label="Marka" required>
              <Select
                onSelect={(type: any) => {
                  const brand = configFile.brand.filter(
                    (brand) => brand.type == type
                  );
                  setSerialState((prev) => ({
                    ...prev,
                    brand: brand[0].shortend,
                  }));
                }}
              >
                {(function myFunction() {
                  return configFile.brand.map((brand) => {
                    return (
                      <Option key={brand.type} value={brand.type}>
                        {brand.text}
                      </Option>
                    );
                  });
                })()}
              </Select>
            </Form.Item>
            {productionUnit == "turbo" && serialState.brand == "T" ? (
              <Form.Item name="model" label="Model" required>
                <Select>
                  {(function myFunction() {
                    return configFile.turktraktormodel.map((brand) => {
                      return (
                        <Option key={brand.text} value={brand.text}>
                          {brand.text}
                        </Option>
                      );
                    });
                  })()}
                </Select>
              </Form.Item>
            ) : null}

            {productionUnit == "turbo" || productionUnit === "kollu" ? (
              <Form.Item name="mixer_type" label="Mixer Tipi">
                <Select
                  onSelect={(type: any) => {
                    const mixer_type = configFile.mixer_type.filter(
                      (mixer_type) => mixer_type.type == type
                    );
                    setSerialState((prev) => ({
                      ...prev,
                      mixer_type: mixer_type[0].shortend,
                    }));
                  }}
                >
                  {(function myFunction() {
                    return configFile?.mixer_type?.map((mixer_type) => {
                      return (
                        <Option key={mixer_type.type} value={mixer_type.type}>
                          {mixer_type.text}
                        </Option>
                      );
                    });
                  })()}
                </Select>
              </Form.Item>
            ) : null}

            <Form.Item name="type" label="Tip" required>
              {productionUnit === "turbo" || productionUnit === "kollu" ? (
                <Select
                  onSelect={(btype: any) => {
                    const type = configFile.type.filter(
                      (type) => type.type == btype
                    );
                    setSerialState((prev) => ({
                      ...prev,
                      type: type[0].shortend,
                    }));
                  }}
                >
                  {(function myFunction() {
                    return configFile.type.map((type) => {
                      return (
                        <Option key={type.type} value={type.type}>
                          {type.text}
                        </Option>
                      );
                    });
                  })()}
                </Select>
              ) : (
                <Select
                  onSelect={(btype: any) => {
                    const type = configFile.machine_type.filter(
                      (machine_type) => machine_type.type == btype
                    );
                    setSerialState((prev) => ({
                      ...prev,
                      type: type[0].shortend,
                    }));
                  }}
                >
                  {(function myFunction() {
                    return configFile.machine_type.map((machine_type) => {
                      return (
                        <Option
                          key={machine_type.type}
                          value={machine_type.type}
                        >
                          {machine_type.text}
                        </Option>
                      );
                    });
                  })()}
                </Select>
              )}
            </Form.Item>
            {productionUnit == "kollu" || productionUnit == "turbo" ? (
              <>
                <Form.Item
                  name="caldron_color"
                  rules={[
                    { required: true, message: "Bu alan boş bırakılamaz" },
                    { max: 32, message: "Maksimum sınıra ulaşıldı." },
                  ]}
                  label="Kazan Rengi"
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="caldron_material"
                  rules={[
                    { required: true, message: "Bu alan boş bırakılamaz" },
                    { max: 19, message: "Maksimum sınıra ulaşıldı." },
                  ]}
                  label="Materyal"
                  required
                >
                  <Select>
                    {(function myFunction() {
                      return configFile.caldron_material.map(
                        (caldron_material) => {
                          return (
                            <Option
                              key={caldron_material.type}
                              value={caldron_material.type}
                            >
                              {caldron_material.text}
                            </Option>
                          );
                        }
                      );
                    })()}
                  </Select>
                </Form.Item>
                <Form.Item name="tank_size" label="Depo Hacmi" required>
                  <Select
                    onSelect={(type: string) => {
                      const tank_size = configFile.tank_size.filter(
                        (tank_size) => tank_size.type == type
                      );
                      setSerialState((prev) => ({
                        ...prev,
                        tank_size: parseInt(tank_size[0].shortend),
                      }));
                    }}
                  >
                    <OptGroup label="Litre">
                      {(function myFunction() {
                        return configFile.tank_size.map((tank_size) => {
                          return (
                            <Option key={tank_size.type} value={tank_size.type}>
                              {tank_size.text}
                            </Option>
                          );
                        });
                      })()}
                    </OptGroup>
                  </Select>
                </Form.Item>
                <Form.Item name="tow_type" label="Çeki Tipi" required>
                  <Select>
                    {(function myFunction() {
                      return configFile.tow_type.map((tow_type) => {
                        return (
                          <Option key={tow_type.type} value={tow_type.type}>
                            {tow_type.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>
                <Form.Item name="pump" label="Pompa" required>
                  <Select
                    onSelect={(type: any) => {
                      const pump = configFile.pump.filter(
                        (pump) => pump.type == type
                      );
                      setSerialState((prev) => ({
                        ...prev,
                        pump: pump[0].shortend,
                      }));
                    }}
                  >
                    {(function myFunction() {
                      return configFile.pump.map((pump) => {
                        return (
                          <Option key={pump.type} value={pump.type}>
                            {pump.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>
                <Form.Item name="commander" label="Kumanda" required>
                  <Select>
                    {(function myFunction() {
                      return configFile.commander.map((commander) => {
                        return (
                          <Option key={commander.type} value={commander.type}>
                            {commander.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="meme_type"
                  rules={[
                    { required: true, message: "Bu alan boş bırakılamaz" },
                    { max: 19, message: "Maksimum sınıra ulaşıldı." },
                  ]}
                  label="Meme Tipi"
                  required
                >
                  <Select>
                    {(function myFunction() {
                      return configFile.meme_type.map((meme_type) => {
                        return (
                          <Option key={meme_type.type} value={meme_type.type}>
                            {meme_type.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>
              </>
            ) : null}
            {productionUnit == "kollu" ? (
              <>
                <Form.Item name="arm_type" label="Kol Tipi" required>
                  <Select
                    onSelect={(btype: any) => {
                      const arm_type = configFile.arm_type.filter(
                        (type) => type.type == btype
                      );
                      setSerialState((prev) => ({
                        ...prev,
                        arm_type: arm_type[0].shortend,
                      }));
                    }}
                  >
                    {(function myFunction() {
                      return configFile.arm_type.map((arm_type) => {
                        return (
                          <Option key={arm_type.type} value={arm_type.type}>
                            {arm_type.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="meme_distance"
                  rules={[
                    { required: true, message: "Bu alan boş bırakılamaz" },
                    { max: 19, message: "Maksimum sınıra ulaşıldı." },
                  ]}
                  label="Meme Aralığı"
                  required
                >
                  <Input maxLength={19} />
                </Form.Item>
                <Form.Item
                  name="arm_length"
                  rules={[
                    { required: true, message: "Bu alan boş bırakılamaz" },
                    { max: 30, message: "Maksimum sınıra ulaşıldı." },
                  ]}
                  label="Kol Uzunluğu"
                  required
                >
                  <Input maxLength={20} />
                </Form.Item>
              </>
            ) : null}

            {productionUnit == "turbo" ? (
              <>
                <Form.Item name="cooler_type" label="Fan Tipi" required>
                  <Select
                    onSelect={(type: any) => {
                      const cooler_type = configFile.cooler_type.filter(
                        (cooler_type) => cooler_type.type == type
                      );
                      setSerialState((prev) => ({
                        ...prev,
                        cooler_type: cooler_type[0].shortend,
                      }));
                    }}
                  >
                    {(function myFunction() {
                      return configFile.cooler_type.map((cooler_type) => {
                        return (
                          <Option
                            key={cooler_type.type}
                            value={cooler_type.type}
                          >
                            {cooler_type.text}
                          </Option>
                        );
                      });
                    })()}
                  </Select>
                </Form.Item>

                {productionUnit == "turbo" ? (
                  <Form.Item name="caldron_type" label="Kazan Tipi" required>
                    <Select>
                      {(function myFunction() {
                        return configFile.caldron_type.map((caldron_type) => {
                          return (
                            <Option
                              key={caldron_type.type}
                              value={caldron_type.type}
                            >
                              {caldron_type.text}
                            </Option>
                          );
                        });
                      })()}
                    </Select>
                  </Form.Item>
                ) : null}

                {productionUnit == "turbo" ? (
                  <Form.Item name="fan_radius" label="Pervane Çapı" required>
                    <Select>
                      {(function myFunction() {
                        return configFile.fan_radius.map((fan_radius) => {
                          return (
                            <Option
                              key={fan_radius.type}
                              value={fan_radius.type}
                            >
                              {fan_radius.text}
                            </Option>
                          );
                        });
                      })()}
                    </Select>
                  </Form.Item>
                ) : null}
                {productionUnit == "turbo" ? (
                  <Form.Item name="fan_type" label="Pervane Tipi" required>
                    <Select>
                      {(function myFunction() {
                        return configFile.fan_type.map((fan_type) => {
                          return (
                            <Option key={fan_type.type} value={fan_type.type}>
                              {fan_type.text}
                            </Option>
                          );
                        });
                      })()}
                    </Select>
                  </Form.Item>
                ) : null}
              </>
            ) : null}

            {!machineId && (
              <Form.Item name="machine_no" label="Makine No">
                <Input
                  disabled
                  value={serialState.machine_no}
                  style={{ width: "324px" }}
                  maxLength={6}
                  onChange={(e) => {
                    setSerialState((prev) => ({
                      ...prev,
                      machine_no: padLeft(e.target.value, "0", 6),
                    }));
                  }}
                />
                <Button
                  disabled={isFetching || !!orderNoCounter}
                  icon={<Spin indicator={<UndoOutlined spin={isFetching} />} />}
                  onClick={createOrderNo}
                />
              </Form.Item>
            )}

            <Form.Item name="chassis_color" label="Şase Rengi" required>
              <Select>
                {(function myFunction() {
                  return configFile.chassis_color.map((chassis_color) => {
                    return (
                      <Option
                        key={chassis_color.type}
                        value={chassis_color.type}
                      >
                        {chassis_color.text}
                      </Option>
                    );
                  });
                })()}
              </Select>
            </Form.Item>

            <Form.Item
              name="alt_features"
              rules={[{ max: 250, message: "Maksimum sınıra ulaşıldı." }]}
              label="Ek Özellikler"
              required
            >
              <Input.TextArea maxLength={250} />
            </Form.Item>
            <Form.Item name="order_tooker" label="Makineyi Oluşturan" required>
              <Select>
                {(function myFunction() {
                  return configFile.order_tooker.map((order_tooker) => {
                    return (
                      <Option key={order_tooker.type} value={order_tooker.type}>
                        {order_tooker.text}
                      </Option>
                    );
                  });
                })()}
              </Select>
            </Form.Item>
            <Form.Item name="serial_number" label="Seri No">
              <Input disabled placeholder={serialCode} />
            </Form.Item>
            <Form.Item name="customer" label="Alınan Müşteri" required>
              <Input />
            </Form.Item>
            <Form.Item
              name="tckn"
              label="TCKN"
              rules={[{ max: 11, message: "Maksimum sınır aşıldı." }]}
              required
            >
              <Input maxLength={11} />
            </Form.Item>
            <Form.Item
              name="address"
              label="Adres"
              rules={[
                { required: true, message: "Bu alan boş bırakılamaz" },
                { max: 250, message: "Maksimum sınır aşıldı." },
              ]}
              required
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone_number"
              label="Telefon No"
              initialValue={"+90"}
              rules={[
                { required: true, message: "Bu alan boş bırakılamaz" },
                {
                  max: 13,
                  min: 13,
                  message: "Telefon numarası 13 haneden oluşmalıdır.",
                },
              ]}
              required
            >
              <Input
                placeholder="Ülke alan koduyla birlikte girin.."
                maxLength={13}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <Form.Item
                style={{ flex: 1, marginLeft: "1.6rem" }}
                required
                name="price"
                label="Alış Fiyatı"
              >
                <Input type="number" />
              </Form.Item>

              <Form.Item
                style={{ width: "80px" }}
                name="currency"
                initialValue="₺"
                required
              >
                <Select>
                  <Option value="₺">₺</Option>
                  <Option value="€">€</Option>
                  <Option value="$">$</Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item wrapperCol={{ span: 24 }}>
              {machineId ? (
                <Button
                  loading={isFetching}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Makineyi Düzenle
                </Button>
              ) : (
                <Button
                  loading={isFetching}
                  disabled={!orderNoCounter}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Makineyi Oluştur{" "}
                  {orderNoCounter || "(Makine No'yu Yenileyin)"}
                </Button>
              )}{" "}
            </Form.Item>
          </Form>
          <div style={{ display: "block", width: "min-content" }}>
            <QRCode value={qrCodeValue} renderAs="svg" size={178} />
          </div>
        </Body>
      </Content>
    </Layout>
  );

  function SerialCodeGenerator(): string {
    switch (productionUnit) {
      case "kollu":
        return (
          "U" +
          serialState.brand +
          serialState.type +
          serialState.arm_type +
          serialState.tank_size +
          serialState.pump +
          0 +
          serialState.machine_no
        );
      case "turbo":
        return (
          "U" +
          serialState.brand +
          serialState.type +
          serialState.cooler_type +
          serialState.tank_size +
          serialState.pump +
          0 +
          serialState.machine_no
        );
      case "diger":
        return (
          "U" +
          serialState.brand +
          serialState.type +
          0 +
          serialState.machine_no
        );
      default:
        return "";
    }
  }
}

export function splitTwoPartsBarcode(str: string) {
  var index = str.indexOf("0"); // Gets the first index where a space occours
  var amount = reverseString(str.substr(0, index)); // Gets the first part
  var serial = reverseString(str.substr(index + 1));

  return [serial, amount];
}

export function reverseString(str: string) {
  var newString = "";
  for (var i = str.length - 1; i >= 0; i--) {
    newString += str[i];
  }
  return newString;
}

export default CreateMachineScreen;
