import { Button, Card, Form, Input, Modal, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CreateServiceLogForMachine,
  GetAllMachineList,
  GetServiceLogForMachine,
} from "../services/ReportService";

const ServiceRecordsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [selectedMachinesDataId, setSelectedMachinesDataId] = useState("");
  const { serial_number } = useParams();

  const [selectableMachinesServices, setSelectableMachinesServices] = useState(
    []
  );
  const [selectedMachineServices, setSelectedMachineServices] = useState([]);
  const parsedResponse = selectedMachineServices?.map((item) =>
    JSON.parse(item.operations)
  );

  const data = parsedResponse?.map((res) => res[0]);

  const columns = [
    {
      title: "Stok No",
      dataIndex: "stockNo",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Malzeme Adı",
      dataIndex: "name",
      sorter: (a, b) => a.age - b.age,
    },

    {
      title: "Birim",
      dataIndex: "unit",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Adet",
      dataIndex: "quantity",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Toplam Tutar",
      dataIndex: "total",
      sorter: (a, b) => a.age - b.age,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const createServiceItemValues = [
    {
      name: "stockNo",
      label: "Stok No",
      required: true,
    },
    {
      name: "name",
      label: "Malzeme Adı",
      required: true,
    },
    {
      name: "unit",
      label: "Birim",
      required: true,
    },
    {
      name: "quantity",
      label: "Adet",
      required: true,
    },
    {
      name: "total",
      label: "Toplam Tutar",
      required: true,
    },
  ];

  useEffect(() => {
    GetAllMachineList().then((res) => {
      setSelectableMachinesServices(res);
    });
  }, []);

  const getServiceLogsForSelectedMachine = async (machineId) => {
    setSelectedMachinesDataId(machineId);
    GetServiceLogForMachine(machineId).then((res) => {
      setSelectedMachineServices(res);
    });
  };
  const createServiceLogForMachine = async (body) => {
    setLoading(true);
    CreateServiceLogForMachine({
      machine_id: selectedMachinesDataId,
      details: "",
      operations: body,
      customer: "",
    }).then((res) => {
      setLoading(false);
      setIsModalOpen(!isModalOpen);

      GetServiceLogForMachine(selectedMachinesDataId).then((res) => {
        setSelectedMachineServices(res);
      });
    });
  };

  useEffect(() => {
    if (serial_number) {
      setSelectedMachinesDataId(serial_number);
      GetServiceLogForMachine(serial_number).then((res) => {
        setSelectedMachineServices(res);
      });
    }
  }, []);

  return (
    <>
      <div>
        <Card>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "50px",
            }}
          >
            <Select
              showSearch
              style={{
                width: 200,
              }}
              onChange={(e) => getServiceLogsForSelectedMachine(e)}
              placeholder="Makina Ara"
              value={selectedMachinesDataId}
            >
              {selectableMachinesServices?.map((item) => (
                <Select.Option
                  key={item?.serial_number}
                  value={item?.serial_number}
                >
                  {item?.serial_number}
                </Select.Option>
              ))}
            </Select>
            <Button
              type="primary"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
              }}
            >
              Yeni Kayıt Oluştur
            </Button>
          </div>
        </Card>
      </div>

      {selectedMachinesDataId?.length !== 0 && (
        <Table columns={columns} dataSource={data} onChange={onChange} />
      )}
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          title="Yeni Kayıt Oluştur"
          okText="Oluştur"
          cancelText="İptal Et"
          onCancel={() => setIsModalOpen(!isModalOpen)}
          confirmLoading={loading}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                createServiceLogForMachine({ ...values });
                form.resetFields();
              })
              .catch((info) => {});
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ modifier: "public" }}
            requiredMark={false}
            style={{
              marginTop: "24px",
            }}
          >
            {createServiceItemValues.map((item) => (
              <Form.Item
                key={item.name}
                name={item.name}
                label={item.label}
                rules={[
                  {
                    required: item.required,
                    message: "Bu alan boş bırakılamaz.",
                  },
                ]}
              >
                <Input name={item.name} type="text" />
              </Form.Item>
            ))}
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ServiceRecordsScreen;
