import {
  BarChartOutlined,
  ClockCircleOutlined,
  GroupOutlined,
  HomeOutlined,
  InboxOutlined,
  IssuesCloseOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  ReconciliationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuTheme } from "antd";
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router";
import styled from "styled-components";
import { logout } from "../redux/actions/authActions";
import { RootState } from "../redux/reducers";
import ActiveOrdersScreen from "./ActiveOrdersScreen";
import ArmProductionTasksScreen from "./ArmProductionTasksScreen";
import ChassisProductionTasksScreen from "./ChassisProductionTasksScreen";
import DeliveryScreen from "./DeliveryScreen";
import FanProductionTasksScreen from "./FanProductionTasksScreen";
import GenericTasksConfigScreen from "./GenericTasksConfigScreen";
import GenericTasksScreen from "./GenericTasksScreen";
import MachineTypeConfigScreen from "./MachineTypeConfigScreen";
import OrderPlanningScreen from "./OrderPlanningScreen";
import OrderScreen from "./OrderScreen.js";
import PendingOrdersScreen from "./PendingOrdersScreen";
import ShortcutsScreen from "./ShortcutsScreen";
import StatisticsScreen from "./StatisticsScreen";
import TankProducerScreen from "./TankProducerScreen";
import UserRollsConfigScreen from "./UserRollsConfigScreen";
import CreateOrderScreen from "./createOrderScreen";
import ManageConfigScreen from "./manageConfigScreen";
import ManageEmployeScreen from "./manageEmployeScreen";

import { useState } from "react";
import IhtiyacListesiConfigScreen from "./IhtiyacListesiConfigScreen";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import ActiveMachineStatus from "./ActiveMachineStatus";
import CreateMachineScreen from "./CreateMachineScreen";
import FilteredOrdersScreen from "./FilteredOrdersScreen";
import OrderScreenForDealer from "./ForDealer/AllOrdersScreen";
import CompletedOrderScreenForDealers from "./ForDealer/CompletedOrders";
import CreateOrderScreenForDealers from "./ForDealer/CreateOrderScreen";
import GenericTaskWithRole from "./GenericTaskWithRole";
import ArmInvoice from "./Invoice/ArmInvoice";
import FanInvoice from "./Invoice/FanInvoice";
import ProformaInvoice from "./Invoice/ProformaInvoice";
import NeedListScreen from "./NeedListScreen";
import OrderLogs from "./OrderLogs";
import OtherNeedListScreen from "./OtherNeedListScreen";
import PendingOrdersScreenForFinance from "./PendingOrdersScreenForFinance";
import QrCodeScreen from "./QrCodeScreen";
import ServiceRecordsScreen from "./ServiceRecordsScreen";
import TankProducerScreenFromShortcuts from "./TankProducerScreenFromShortcuts";
import UsedMachinesScreen from "./UsedMachinesScreen";
import EditOrderScreen from "./editOrderScreen";
import ProducedMaterials from "./producedMaterials";

dayjs.locale("tr");

function MainScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const [theme, setTheme] = useState<MenuTheme>("light");

  const changeTheme = (value: boolean) => {
    setTheme(value ? "dark" : "light");
  };

  return (
    <MainContainer>
      <Menu
        defaultSelectedKeys={[window.location.pathname.split("/")[1]]}
        onClick={(info) => {
          switch (info.key) {
            case "ihtiyacListesiConfig":
              history.push("ihtiyacListesiConfig");
              break;
            case "GenericTasksScreen":
              history.push("GenericTasksScreen");
              break;
            case "machineTypeConfig":
              history.push("machineTypeConfig");
              break;
            case "userRollsConfig":
              history.push("userRollsConfig");
              break;
            case "manageConfig":
              history.push("manageConfig");
              break;
            case "createOrder":
              history.push("/createOrder");
              break;
            case "createMachine":
              history.push("/createMachine");
              break;

            case "reports":
              history.push("/reports");
              break;
            case "users":
              history.push("/users");
              break;
            case "deliveries":
              history.push("/deliveries");
              break;
            case "pendingorders":
              history.push("/pendingorders");
              break;
            case "pendingordersforfinance":
              history.push("/pendingordersforfinance");
              break;
            case "tankproducer":
              history.push("/tankproducer");
              break;
            case "producedMaterials":
              history.push("/producedMaterials");
              break;
            case "activeorders":
              history.push("/activeorders");
              break;
            case "usedmachines":
              history.push("/usedmachines");
              break;
            case "chassisproduction":
              history.push("/chassisproductiontasks");
              break;
            case "fanproduction":
              history.push("/fanproductiontasks");
              break;
            case "armproduction":
              history.push("/armproductiontasks");
              break;
            case "productionplans":
              history.push("/planning");
              break;
            case "finishedorders":
              history.push("/finishedorders");
              break;

            case "cancelledorders":
              history.push("/cancelledorders");
              break;
            case "orders":
              history.push("/orders");
              break;
            case "activegenerictasks":
              history.push("/activegenerictasks");
              break;
            case "finishedgenerictasks":
              history.push("/finishedgenerictasks");
              break;

            case "chassisneedlist":
              history.push("/chassisneedlist");
              break;
            case "fanneedlist":
              history.push("/fanneedlist");
              break;
            case "pumpneedlist":
              history.push("/pumpneedlist");
              break;
            case "towneedlist":
              history.push("/towneedlist");
              break;
            case "commanderneedlist":
              history.push("/commanderneedlist");
              break;
            case "otherneedlist":
              history.push("/otherneedlist");
              break;
            case "generictasksconfig":
              history.push("/generictasksconfig");
              break;
            case "servicerecords":
              history.push("/servicerecords");
              break;
            case "shortcuts":
              history.push("/shortcuts");
              break;
            case "editOrder":
              history.push("/editOrder");
              break;
            case "qrcode":
              history.push("/qrcode");
              break;
            case "armInvoice":
              history.push("/armInvoice");
              break;
            case "fanInvoice":
              history.push("/fanInvoice");
              break;
            case "proformaInvoice":
              history.push("/proformaInvoice");
              break;
            case "createOrderForDealer":
              history.push("/createOrderForDealer");
              break;
            case "ordersForDealer":
              history.push("/ordersForDealer");
              break;
            case "finishedordersfordealers":
              history.push("/finishedordersfordealers");
              break;
            case "logs":
              history.push("/logs");
              break;

            default:
              history.push("/");
              break;
          }
          changeTheme(false);
        }}
        mode="horizontal"
        theme={theme}
      >
        <Menu.Item
          style={{ float: "right", pointerEvents: "none" }}
          key="watchtome"
          icon={<ClockCircleOutlined />}
        >
          {dayjs().format("D MMMM YYYY dddd")}
        </Menu.Item>

        {user.role === "bayi" && (
          <>
            <Menu.Item
              key="createOrderForDealer"
              icon={<ReconciliationOutlined />}
            >
              Sipariş Oluştur
            </Menu.Item>

            <Menu.SubMenu
              key="OrdersSubMenu"
              icon={<BarChartOutlined />}
              title="Siparişler"
            >
              <Menu.Item key="ordersForDealer" icon={<BarChartOutlined />}>
                Aktif Bayi Siparişleri
              </Menu.Item>
              <Menu.Item
                key="finishedordersfordealers"
                icon={<BarChartOutlined />}
              >
                Tamamlanan Siparişler
              </Menu.Item>
            </Menu.SubMenu>
          </>
        )}

        {user.role === "admin" || user.role === "muhasebe" ? (
          <>
            <Menu.Item key="" icon={<HomeOutlined />}>
              Anasayfa
            </Menu.Item>
            {user.role === "admin" ? (
              <Menu.Item key="users" icon={<UserOutlined />}>
                Kullanıcıları Yönet
              </Menu.Item>
            ) : null}
            {user.role === "admin" ? (
              <Menu.SubMenu
                key="createOrder"
                icon={<ReconciliationOutlined />}
                title="Sipariş Oluştur"
                onTitleClick={() => {
                  history.push("/createOrder");
                }}
              >
                <Menu.Item key="createMachine" icon={<GroupOutlined />}>
                  İkinci El Makine Oluştur
                </Menu.Item>
              </Menu.SubMenu>
            ) : null}

            <Menu.SubMenu
              key="OrdersSubMenu"
              icon={<BarChartOutlined />}
              title="Siparişler"
            >
              <Menu.Item key="orders" icon={<BarChartOutlined />}>
                Tüm Siparişler
              </Menu.Item>
              <Menu.Item key="finishedorders" icon={<BarChartOutlined />}>
                Tamamlanan Siparişler
              </Menu.Item>
              <Menu.Item key="cancelledorders" icon={<BarChartOutlined />}>
                İptal Edilen Siparişler
              </Menu.Item>
              <Menu.Item key="pendingorders" icon={<IssuesCloseOutlined />}>
                Onay Bekleyen Makineler
              </Menu.Item>
              {(user.secondRole === "financemanager" ||
                user.secondRole === "generalmanager") && (
                <Menu.Item
                  key="pendingordersforfinance"
                  icon={<IssuesCloseOutlined />}
                >
                  Finans Onayı Bekleyenler
                </Menu.Item>
              )}
              <Menu.Item key="activeorders" icon={<OrderedListOutlined />}>
                Aktif Makineler
              </Menu.Item>
              <Menu.Item key="usedmachines" icon={<OrderedListOutlined />}>
                İkinci El Makineler
              </Menu.Item>
              <Menu.Item key="logs" icon={<BarChartOutlined />}>
                Sipariş Kayıtları
              </Menu.Item>
            </Menu.SubMenu>
          </>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "ambar1" ||
        user.role === "ambar2" ? (
          <>
            <Menu.SubMenu
              key="ProductionsSubMenu"
              icon={<BarChartOutlined />}
              title="Üretim"
            >
              <Menu.Item key="productionplans" icon={<GroupOutlined />}>
                Üretim Planlama
              </Menu.Item>
              <Menu.Item key="tankproducer" icon={<GroupOutlined />}>
                Depo Üretici
              </Menu.Item>
              <Menu.Item key="chassisproduction" icon={<GroupOutlined />}>
                Şase Üretimdekiler
              </Menu.Item>
              <Menu.Item key="fanproduction" icon={<GroupOutlined />}>
                Fan Üretimdekiler
              </Menu.Item>
              <Menu.Item key="armproduction" icon={<GroupOutlined />}>
                Kol Üretimdekiler
              </Menu.Item>
              <Menu.Item key="producedMaterials" icon={<GroupOutlined />}>
                Kullanılan Malzemeler
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.Item key="deliveries" icon={<InboxOutlined />}>
              Teslimatlar
            </Menu.Item>
          </>
        ) : null}
        {user.role === "tankproducer" ? (
          <Menu.Item key="tankproducer" icon={<GroupOutlined />}>
            Depo Üretici
          </Menu.Item>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "ambar1" ||
        user.role === "ambar2" ? (
          <>
            <Menu.SubMenu
              key="GenericTasksSubmenu"
              icon={<BarChartOutlined />}
              title="İş Emirleri"
            >
              <Menu.Item key="activegenerictasks" icon={<GroupOutlined />}>
                Aktif İş Emirleri
              </Menu.Item>
              <Menu.Item key="finishedgenerictasks" icon={<GroupOutlined />}>
                Tamamlanan İş Emirleri
              </Menu.Item>
              <Menu.Item key="generictasksconfig" icon={<GroupOutlined />}>
                Yetkilendirme Ayarları
              </Menu.Item>
              <Menu.Item key="shortcuts" icon={<GroupOutlined />}>
                Kısayollar
              </Menu.Item>
              <Menu.Item key="servicerecords" icon={<GroupOutlined />}>
                Servis Kayıtlar
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="NeedListSubmenu"
              icon={<BarChartOutlined />}
              title="İhtiyaç Listesi"
            >
              <Menu.Item key="chassisneedlist" icon={<GroupOutlined />}>
                Şase İhtiyaç Listesi
              </Menu.Item>
              <Menu.Item key="pumpneedlist" icon={<GroupOutlined />}>
                Pompa İhtiyaç Listesi
              </Menu.Item>
              <Menu.Item key="towneedlist" icon={<GroupOutlined />}>
                Çeki İhtiyaç Listesi
              </Menu.Item>
              <Menu.Item key="fanneedlist" icon={<GroupOutlined />}>
                Fan İhtiyaç Listesi
              </Menu.Item>
              <Menu.Item key="commanderneedlist" icon={<GroupOutlined />}>
                Kumanda İhtiyaç Listesi
              </Menu.Item>
              <Menu.Item key="otherneedlist" icon={<GroupOutlined />}>
                Diğer İhtiyaç Listeleri
              </Menu.Item>
            </Menu.SubMenu>
          </>
        ) : null}
        {user.role === "lazer" ||
        user.role === "lazer2" ||
        user.role === "lazerkesim" ||
        user.role === "planlama" ||
        user.role === "robot1" ||
        user.role === "robot2" ? (
          <Menu.Item key="activegenerictasks" icon={<GroupOutlined />}>
            Aktif İş Emirleri
          </Menu.Item>
        ) : null}
        {user.role === "depo" ? (
          <>
            <Menu.Item key="mainscreen" icon={<HomeOutlined />}>
              Anasayfa
            </Menu.Item>
            <Menu.Item key="activeorders" icon={<OrderedListOutlined />}>
              Aktif Makineler
            </Menu.Item>
            <Menu.Item key="usedmachines" icon={<OrderedListOutlined />}>
              İkinci El Makineler
            </Menu.Item>
            <Menu.Item key="activegenerictasks" icon={<GroupOutlined />}>
              Aktif İş Emirleri
            </Menu.Item>
            <Menu.Item key="productionplans" icon={<GroupOutlined />}>
              Üretim Planlama
            </Menu.Item>
            <Menu.SubMenu
              key="NeedListSubmenu"
              icon={<BarChartOutlined />}
              title="İhtiyaç Listesi"
            >
              <Menu.Item key="chassisneedlist" icon={<GroupOutlined />}>
                Şase İhtiyaç Listesi
              </Menu.Item>
              <Menu.Item key="pumpneedlist" icon={<GroupOutlined />}>
                Pompa İhtiyaç Listesi
              </Menu.Item>
              <Menu.Item key="towneedlist" icon={<GroupOutlined />}>
                Çeki İhtiyaç Listesi
              </Menu.Item>
              <Menu.Item key="fanneedlist" icon={<GroupOutlined />}>
                Fan İhtiyaç Listesi
              </Menu.Item>
              <Menu.Item key="commanderneedlist" icon={<GroupOutlined />}>
                Kumanda İhtiyaç Listesi
              </Menu.Item>
              <Menu.Item key="otherneedlist" icon={<GroupOutlined />}>
                Diğer İhtiyaç Listeleri
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
              key="ProductionsSubMenu"
              icon={<BarChartOutlined />}
              title="Üretim"
            >
              <Menu.Item key="chassisproduction" icon={<GroupOutlined />}>
                Şase Üretimdekiler
              </Menu.Item>
              <Menu.Item key="fanproduction" icon={<GroupOutlined />}>
                Fan Üretimdekiler
              </Menu.Item>
              <Menu.Item key="armproduction" icon={<GroupOutlined />}>
                Kol Üretimdekiler
              </Menu.Item>
            </Menu.SubMenu>
          </>
        ) : null}
        {user.role === "admin" ? (
          <>
            <Menu.SubMenu
              theme={theme}
              key="manageConfig"
              icon={<OrderedListOutlined />}
              title="Menüleri Yönet"
            >
              <Menu.Item key="userRollsConfig" icon={<GroupOutlined />}>
                Kullanıcı Rolleri
              </Menu.Item>
              <Menu.Item key="GenericTasksScreen" icon={<GroupOutlined />}>
                Kullanıcı Yetkileri
              </Menu.Item>
              <Menu.Item key="machineTypeConfig" icon={<GroupOutlined />}>
                Makine Tipleri
              </Menu.Item>
              <Menu.Item key="ihtiyacListesiConfig" icon={<GroupOutlined />}>
                Ihtiyac Listesi
              </Menu.Item>
            </Menu.SubMenu>
          </>
        ) : null}
        <Menu.Item
          onClick={() => {
            dispatch(logout());
          }}
          style={{ float: "right" }}
          key="logout"
          icon={<LogoutOutlined />}
        >
          Çıkış Yap
        </Menu.Item>
      </Menu>
      <Switch>
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "ambar1" ||
        user.role === "ambar2" ||
        user.role === "depo" ? (
          <Route path="/otherneedlist" exact>
            <OtherNeedListScreen />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "ambar1" ||
        user.role === "ambar2" ||
        user.role === "depo" ? (
          <Route path="/chassisneedlist" exact>
            <NeedListScreen type="Şase" />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "ambar1" ||
        user.role === "ambar2" ||
        user.role === "depo" ? (
          <Route path="/pumpneedlist" exact>
            <NeedListScreen type="Pompa" />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "ambar1" ||
        user.role === "ambar2" ||
        user.role === "depo" ? (
          <Route path="/commanderneedlist" exact>
            <NeedListScreen type="Kumanda" />
          </Route>
        ) : null}

        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "ambar1" ||
        user.role === "ambar2" ||
        user.role === "depo" ? (
          <Route path="/fanneedlist" exact>
            <NeedListScreen type="Fan" />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "ambar1" ||
        user.role === "ambar2" ||
        user.role === "depo" ? (
          <Route path="/towneedlist" exact>
            <NeedListScreen type="Çeki" />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/ihtiyacListesiConfig">
            <IhtiyacListesiConfigScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/manageConfig">
            <ManageConfigScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/userRollsConfig">
            <UserRollsConfigScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/machineTypeConfig">
            <MachineTypeConfigScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/GenericTasksScreen">
            <GenericTasksConfigScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route
            path="/GenericTaskWithRoleScreen"
            render={(props) => <GenericTaskWithRole {...props} />}
          />
        ) : null}
        {user.role === "admin" ? (
          <Route path="/generictasksconfig" exact>
            <GenericTasksConfigScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/servicerecords/:serial_number?">
            <ServiceRecordsScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/shortcuts" exact>
            <ShortcutsScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/qrcode" exact>
            <QrCodeScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/createOrder">
            <CreateOrderScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/createMachine">
            <CreateMachineScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/editOrder/:serial_number">
            <EditOrderScreen />
          </Route>
        ) : null}
        {user.role === "bayi" ? (
          <Route path="/createOrderForDealer">
            <CreateOrderScreenForDealers />
          </Route>
        ) : null}
        {user.role === "bayi" ? (
          <Route path="/ordersForDealer">
            <OrderScreenForDealer />
          </Route>
        ) : null}
        {user.role === "bayi" ? (
          <Route path="/finishedordersfordealers">
            <CompletedOrderScreenForDealers />
          </Route>
        ) : null}
        {user.role === "bayi" ? (
          <Route path="/">
            <OrderScreenForDealer />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/users">
            <ManageEmployeScreen />
          </Route>
        ) : null}
        {user.role === "admin" || user.role === "muhasebe" ? (
          <Route path="/pendingorders">
            <PendingOrdersScreen />
          </Route>
        ) : null}
        {user.secondRole === "financemanager" ||
        user.secondRole === "generalmanager" ? (
          <Route path="/pendingordersforfinance">
            <PendingOrdersScreenForFinance />
          </Route>
        ) : null}
        {user.role === "admin" || user.role === "muhasebe" ? (
          <Route path="/deliveries">
            <DeliveryScreen />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "tankproducer" ? (
          <Route path="/tankproducer">
            <TankProducerScreen />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/producedMaterials">
            <ProducedMaterials />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "tankproducer" ? (
          <Route path="/tankProducerFromShortcuts">
            <TankProducerScreenFromShortcuts />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/activeMachines" exact>
            <ActiveMachineStatus />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route path="/logs" exact>
            <OrderLogs />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "depo" ? (
          <Route path="/activeorders">
            <ActiveOrdersScreen />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "depo" ? (
          <Route path="/usedmachines">
            <UsedMachinesScreen history={history} />
          </Route>
        ) : null}
        {user.role === "admin" || user.role === "muhasebe" ? (
          <Route path="/orders">
            <OrderScreen isFinishedOrders={false} isCancelledOrders={false} />
          </Route>
        ) : null}
        {user.role === "admin" || user.role === "muhasebe" ? (
          <Route path="/finishedorders">
            <OrderScreen isFinishedOrders={true} isCancelledOrders={false} />
          </Route>
        ) : null}
        {user.role === "admin" ? (
          <Route
            path="/filteredOrders/:type"
            render={(props: any) => (
              <FilteredOrdersScreen
                isFinishedOrders={true}
                isCancelledOrders={false}
                {...props}
              />
            )}
          ></Route>
        ) : null}
        {user.role === "admin" || user.role === "muhasebe" ? (
          <Route path="/cancelledorders">
            <OrderScreen isFinishedOrders={false} isCancelledOrders={true} />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "ambar1" ||
        user.role === "ambar2" ||
        user.role === "depo" ? (
          <Route path="/planning">
            <OrderPlanningScreen />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "depo" ? (
          <Route path="/chassisproductiontasks">
            <ChassisProductionTasksScreen />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "depo" ? (
          <Route path="/fanproductiontasks">
            <FanProductionTasksScreen />
          </Route>
        ) : null}
        {user.role === "admin" || user.role === "muhasebe" ? (
          <Route path="/proformaInvoice">
            <ProformaInvoice />
          </Route>
        ) : null}
        {user.role === "admin" || user.role === "muhasebe" ? (
          <Route path="/armInvoice">
            <ArmInvoice />
          </Route>
        ) : null}
        {user.role === "admin" || user.role === "muhasebe" ? (
          <Route path="/fanInvoice">
            <FanInvoice />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "depo" ? (
          <Route path="/armproductiontasks">
            <ArmProductionTasksScreen />
          </Route>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "ambar1" ||
        user.role === "ambar2" ? (
          <Route path="/activegenerictasks" exact>
            <GenericTasksScreen isActive />
          </Route>
        ) : null}
        {!(
          user.role === "admin" ||
          user.role === "muhasebe" ||
          user.role === "ambar1" ||
          user.role === "ambar2"
        ) ? (
          <>
            <Route path="/" exact>
              <GenericTasksScreen isActive />
            </Route>
          </>
        ) : null}
        {user.role === "admin" ||
        user.role === "muhasebe" ||
        user.role === "ambar1" ||
        user.role === "ambar2" ? (
          <Route path="/finishedgenerictasks" exact>
            <GenericTasksScreen isActive={false} />
          </Route>
        ) : null}

        <Route path="/">
          <StatisticsScreen />
        </Route>
      </Switch>
    </MainContainer>
  );
}

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const Body = styled.div`
  background-color: rgb(247, 249, 251);
  height: calc(100% - 48px);
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 1rem;
`;
export default MainScreen;
