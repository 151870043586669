import { Button, DatePicker, Form, Row, Typography } from "antd";
import { useHistory } from "react-router";
import {
  ExportCSVModel,
  ExportCSVReportProducedMachines,
} from "../services/ReportService";

import dayjs from "dayjs";
import "dayjs/locale/tr";

dayjs.locale("tr");

const { Title } = Typography;
const { RangePicker } = DatePicker;

function ExportProducedMachines() {
  const history = useHistory();
  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 24 },
  };

  const onFinish = (values: any) => {
    let model: any = new ExportCSVModel();

    values["bulundugu-konum"]?.forEach((value: any) => {
      model[value] = true;
    });

    model.OrderStartDate = values["siparis-tarihi"][0];
    model.OrderEndDate = values["siparis-tarihi"][1];
    model.StatusStartDate = values["siparis-tarihi"][0];
    model.StatusEndDate = values["siparis-tarihi"][0];

    ExportCSVReportProducedMachines(model).then((res) => {
      if (res) {
        history.push("/");
        //setCSVData(res);
      }
    });
  };

  return (
    <div
      style={{
        boxSizing: "border-box",
        overflow: "auto",
        maxWidth: "100%",
        margin: 10,
        borderRadius: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          width: "100%",
          backgroundColor: "rgb(240,240,240)",
          padding: "10px 5%",
        }}
      >
        <Title level={4}>Belirli Aralıkta Üretilen Makineler</Title>
        <Form
          {...formItemLayout}
          name="reportPrefences"
          onFinish={onFinish}
          initialValues={{
            "bulundugu-konum": ["turbo", "kollu", "diger", "test"],
            // "siparis-tarihi": [dayjs().startOf("year"), dayjs().add(1, "days")],
            // "son-islem-tarihi": [
            //   dayjs().startOf("year"),
            //   dayjs().add(1, "days"),
            // ],
          }}
        >
          <Form.Item name="siparis-tarihi">
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
          <Row
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <Button htmlType="submit" type="primary">
              Excel'e Aktar
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default ExportProducedMachines;
